import React, { useEffect, useState } from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Typography,
  Grid,
  Paper,
  Tabs,
  Tab,
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import { styled, keyframes } from '@mui/system';
import YouTubeIcon from '@mui/icons-material/YouTube';
import AppsIcon from '@mui/icons-material/Apps';
import TelegramIcon from '@mui/icons-material/Telegram';
import CloseIcon from '@mui/icons-material/Close';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axios from 'axios';
import { apiURL } from '../api';
import { message } from 'antd';

// Helper function to map icon names to actual React components
const iconMap = {
  AdsClickIcon: <AdsClickIcon />,
  AppsIcon: <AppsIcon />,
  TelegramIcon: <TelegramIcon />,
  YouTubeIcon: <YouTubeIcon />,
  XIcon: <XIcon />,
  FacebookIcon: <FacebookIcon />,
  InstagramIcon: <InstagramIcon />,
};

const getIcon = (iconName) => {
  return iconMap[iconName] || <MonetizationOnIcon />; // Default icon if not found
};

// Animations
const slideIn = keyframes`
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const floating = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
`;

const glow = keyframes`
  0% {
    text-shadow: 0 0 5px #fff, 0 0 10px #f1c40f, 0 0 15px #f1c40f, 0 0 20px #f1c40f;
  }
  100% {
    text-shadow: 0 0 10px #fff, 0 0 20px #f1c40f, 0 0 30px #f1c40f, 0 0 40px #f1c40f;
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.08);
  }
  100% {
    transform: scale(1);
  }
`;

// Styled components
const AnimatedModal = styled(Box)(({ theme }) => ({
  animation: `${slideIn} 0.6s ease-out`,
  background: 'linear-gradient(135deg, #110830 0%, #4A00E0 100%)',
  padding: '20px',
  borderRadius: '20px',
  width: '90%',
  maxWidth: '400px',
  margin: '4vh auto',
  maxHeight: '90vh',
  overflowY: 'auto',
  boxShadow: '0px 15px 30px rgba(0, 0, 0, 0.5)',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    padding: '15px',
    maxWidth: '95%',
  },
}));

const TaskCard = styled(Paper)(({ theme, status }) => ({
  borderRadius: '12px',
  padding: '15px',
  display: 'flex',
  alignItems: 'center',
  background: '#ffffff',
  color: '#333',
  boxShadow: status === 'completed' ? '0px 6px 20px rgba(76, 175, 80, 0.3)' : '0px 6px 20px rgba(0, 0, 0, 0.1)',
  border: status === 'completed' ? '2px solid #81C784' : 'none',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  animation: `${status === 'completed' ? '' : pulse} 2s infinite`,
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0px 15px 25px rgba(0, 0, 0, 0.2)',
  },
}));

const GradientIconWrapper = styled('div')(({ color }) => ({
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  background: `linear-gradient(135deg, ${color}, ${color}CC)`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '15px',
  boxShadow: `0px 4px 12px ${color}`,
  color: '#fff',
  animation: `${floating} 3s ease-in-out infinite`,
}));

// Main component
const TaskModal = ({ open, handleClose, telegram_id, fetchUserData = f => f, }) => {
  const [tabValue, setTabValue] = React.useState(0);
  const [tasks, setTasks] = useState({ incomplete: [], completed: [] });
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchTasks = async () => {
    try {
      const response = await axios.get(`${apiURL}tasks/all/incomplate/tasks/${telegram_id}`);
      setTasks({
        incomplete: response.data.incompleteTasks,
        completed: response.data.completedTasks,
      });
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  useEffect(() => {
    if (open) {
      fetchTasks();
    }
  }, [open]);

  const handleTaskClick = (task) => {
    setSelectedTask(task);
    setConfirmationOpen(true);
  };

  const handleConfirmTask = async () => {
    if (selectedTask.link) {
      const taskWindow = window.open(selectedTask.link, '_blank');
      const taskDuration = 10000; // 10 seconds minimum
  
      let hasSpentEnoughTime = false;
      const startTime = new Date().getTime();
  
      // Function to check how much time has passed
      const checkTimeSpent = () => {
        const currentTime = new Date().getTime();
        const timeSpent = currentTime - startTime;
        return timeSpent >= taskDuration;
      };
  
      // Listen for when the user returns to the app
      const handleFocus = () => {
        if (!checkTimeSpent()) {
          // alert('You need to spend at least 10 seconds on the external page to receive rewards. Please go back.')
          window.Telegram.WebApp.showAlert('You need to spend at least 10 seconds on the external page to receive rewards. Please go back.');
        } else {
          hasSpentEnoughTime = true;
          taskWindow.close();
        }
      };
  
      // Add event listeners for window focus (when the user returns)
      window.addEventListener("focus", handleFocus);
  
      const intervalId = setInterval(async () => {
        // If user has spent enough time and returned
        if (hasSpentEnoughTime) {
          clearInterval(intervalId);
  
          // Grant the reward and update UI
          try {
            await axios.post(`${apiURL}task/user/complete/tasks`, {
              telegram_id: telegram_id,
              task_id: selectedTask.id,
            });
            fetchTasks(); // Refresh the task list
            fetchUserData()
          } catch (error) {
            console.error('Error completing task:', error);
          }
  
          // Remove event listeners after the task is complete
          window.removeEventListener("focus", handleFocus);
        }
      }, 1000); // Check every second
    }
    setConfirmationOpen(false);
  };
  

  const filteredTasks = tabValue === 0 ? tasks.incomplete : tasks.completed;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backdropFilter: 'blur(5px)' },
      }}
    >
      <Fade in={open}>
        <AnimatedModal>
          {/* Close Button */}
          <CloseIcon
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: '15px',
              right: '15px',
              cursor: 'pointer',
              color: '#FFFFFF',
              '&:hover': { color: '#FFCDD2' },
            }}
          />

          {/* Top Section */}
          <Box sx={{ textAlign: 'center', mb: 3 }}>
            <MonetizationOnIcon
              sx={{
                fontSize: 50,
                color: '#F1C40F',
                animation: `${glow} 2s infinite`,
              }}
            />
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
                color: '#FFFFFF',
                mt: 1,
              }}
            >
              Earn More Coins
            </Typography>
          </Box>

          {/* Tabs */}
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{
              mb: 3,
              backgroundColor: '#6200ea',
              borderRadius: '8px',
              '& .MuiTabs-indicator': {
                backgroundColor: '#F1C40F',
              },
            }}
          >
            <Tab
              label="Tasks"
              sx={{
                color: '#FFFFFF',
                fontWeight: 'bold',
                fontSize: '0.875rem',
              }}
            />
            <Tab
              label="Completed"
              sx={{
                color: '#FFFFFF',
                fontWeight: 'bold',
                fontSize: '0.875rem',
              }}
            />
          </Tabs>

          {/* Task List */}
          <Grid container spacing={2}>
            {filteredTasks.map((task) => (
              <Grid key={task.id} item xs={12}>
                <TaskCard status={task.status}>
                  <GradientIconWrapper color={task.color}>
                    {getIcon(task.icon)}
                  </GradientIconWrapper>
                  <Box sx={{ flex: 1, textAlign: 'left' }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 'bold',
                        color: '#333',
                        fontSize: '1rem',
                        textShadow: '0 1px 2px rgba(0,0,0,0.2)',
                      }}
                    >
                      {task.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#555',
                        fontSize: '0.85rem',
                        animation: `${glow} 1.5s infinite`,
                      }}
                    >
                      Reward: {task.reward || 'N/A'}
                    </Typography>
                  </Box>

                  {/* Action Button as Icon */}
                  {task.status === 'active' && task.link && (
                    <IconButton
                      onClick={() => handleTaskClick(task)}
                      sx={{
                        color: '#F1C40F',
                        '&:hover': {
                          color: '#D4AC0D',
                        },
                      }}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  )}
                </TaskCard>
              </Grid>
            ))}
          </Grid>

          {/* Confirmation Dialog */}
          <Dialog
            open={confirmationOpen}
            onClose={() => setConfirmationOpen(false)}
            aria-labelledby="confirmation-dialog-title"
          >
            <DialogTitle id="confirmation-dialog-title">
              Task Confirmation
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                You will be taken to an external page to complete this task. 
                Rewards will be credited after confirmation.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmationOpen(false)} color="secondary">
                Cancel
              </Button>
              <Button onClick={handleConfirmTask} color="primary">
                Proceed
              </Button>
            </DialogActions>
          </Dialog>
        </AnimatedModal>
      </Fade>
    </Modal>
  );
};

export default TaskModal;
