import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, Typography, TextField, Avatar, Divider, Badge } from '@mui/material';
import { Brush, Group, Leaderboard, RocketLaunch, CheckCircle, AccountBalanceWallet } from '@mui/icons-material';
import { GiCoins, GiCheckMark, GiTown, GiSwapBag, GiCat } from 'react-icons/gi';
import { styled } from '@mui/system';
import { message } from 'antd';
import { CssBaseline, ThemeProvider, createTheme, LinearProgress } from '@mui/material';
import SkinsModal from './Skins';
import FriendsModal from './Freinds';
import LeaderboardModal from './Leaderboard';
import { keyframes } from '@emotion/react';
import MyProgress from './Progress';
import axios from 'axios';
import leafRight from '../images/leaf-right.png';

// import images of skins
import defaultCoin from '../images/Coin.png';
import OrangeCoin from '../images/Orange.png';
import GuardCoin from '../images/Guard.png';
import BattleCoin from '../images/Battle.png';
import { apiURL } from '../api';
import TaskModal from './Task';

const isDesktop = window.innerWidth > 1000;
const theme = createTheme();

// Styled components for the gold buttons
const GoldButton = styled(Button)({
    backgroundColor: 'transparent',
    borderRadius: 15,
    width: '20vw',
    margin: '10px',
    padding: window.innerHeight < 740 ? '5px' : '10px',
    fontFamily: 'avenir',
    fontSize: '19px',
    textTransform: 'Capitalize',
    fontWeight: 800,
    boxShadow: 'none',
    '&:hover': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
    },
});

const CoinLogo = styled(Box)({
    width: '35vw',
    marginBottom: '15px',
    // filter: 'hue-rotate(12deg) drop-shadow(0px 0px 25px #0152AC)',
    [theme.breakpoints.down('md')]: {
        width: window.innerHeight < 740 ? '67vw' : '75vw',
        marginBottom: window.innerHeight < 740 ? '10px' : '35px',
    },
})

// Define the pulse animation
const glow = keyframes`
0% {
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0px 0px 30px rgba(255, 255, 0, 1); /* Gold shadow effect */
  }
`;

const incomplateTask = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.3);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;


// keyframes for animation
const expand = keyframes`
   from, to { width: ${isDesktop ? '33vw' : '73vw'}; }
   20% { width: ${isDesktop ? '28.5vw' : '68vw'}; }
   50% { width: ${isDesktop ? '30vw' : '70vw'}; }
`;




const fontSizeAnim = keyframes`
   from, to { font-size: ${isDesktop ? '22px' : '26px'}; }
   50% { font-size: ${isDesktop ? '22px' : '26px'}; }
`;

const floatUpAndFadeOut = keyframes`
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(-100px);
    opacity: 0;
  }
`;

export default function CoinApp(props) {
    const { userData, profileUrl, telApp, telegram_id, pointCount, setPointCount, miningInfo, setMiningInfo, fetchUserData = f => f } = props;
    const [openWithdraw, setOpenWithdraw] = useState(false);
    const [openSkins, setOpenSkins] = useState(false);
    const [openTask, setOpenTask] = useState(false);
    const [openFriends, setOpenFriends] = useState(false);
    const [openLeaderboard, setOpenLeaderboard] = useState(false);
    const [expandAnimation, setExpandAnimation] = useState('');
    const [fontSizeAnimation, setFontSizeAnimation] = useState('');
    const [textPoints, setTextPoints] = useState([]);
    const [userAddress, setUserAddress] = useState('');
    const [userSkins, setUserSkins] = useState([]);
    const [userCurrentSkinID, setUserCurrentSkinID] = useState();
    const [userCurrentSkinImage, setUserCurrentSkinImage] = useState(0);
    const [userCurrentReferrals, setUserCurrentReferrals] = useState(0);
    const [userReferralsInfo, setUserReferralsInfo] = useState([]);
    const [userCurrentRank, setUserCurrentRank] = useState(null);
    const [leaderboardList, setLeaderboardList] = useState([]);
    const [taskList, setTaskList] = useState([]);
    const [audio] = useState(new Audio('https://assets.mixkit.co/active_storage/sfx/216/216.wav'));



    useEffect(() => {
        const interval = setInterval(() => {
            setMiningInfo((prevMiningInfo) => {
                if (prevMiningInfo.limit < prevMiningInfo.max) {
                    return { ...prevMiningInfo, limit: prevMiningInfo.limit + 1 };
                } else {
                    clearInterval(interval);
                    return prevMiningInfo;
                }
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [miningInfo.limit]);

    useEffect(() => {
        const req = async () => {
            await axios.post(`${apiURL}user/${telegram_id}/add-point`, {
                points: miningInfo.perClick,
            })
                .then(response => {
                    console.log('Score was updated:', response.data);
                    // Additional code to handle the response...
                })
                .catch(error => {
                    console.error('Error updating score:', error);
                    // Additional code to handle the error...
                });
        }
        req()
    }, [pointCount])

    // alert(telegram_id)

    useEffect(() => {
        const req = async () => {
            await axios.get(`${apiURL}user/${telegram_id}`)
                .then(response => {
                    const userCurrentSkinID = response.data.skin_id;
                    setUserSkins(response.data.skins);
                    setUserCurrentSkinID(userCurrentSkinID);
                    setUserCurrentReferrals(response.data.referrals);
                    setUserReferralsInfo(response.data.ReferralsAsReferrer);
                    // console.log("========== userReferralsInfo", userReferralsInfo)
                    // set user images
                    switch (userCurrentSkinID) {
                        case 1:
                            setUserCurrentSkinImage(defaultCoin);
                            break;
                        case 2:
                            setUserCurrentSkinImage(OrangeCoin);
                            break;
                        case 3:
                            setUserCurrentSkinImage(GuardCoin);
                            break;
                        case 4:
                            setUserCurrentSkinImage(BattleCoin);
                            break;
                        default:
                            setUserCurrentSkinImage(defaultCoin);

                    }
                })
                .catch(error => {
                    console.error('Error getting skins:', error);
                    // Additional code to handle the error...
                });

            // await axios.get(`${apiURL}tasks/all/incomplate/tasks/${telegram_id}`)
            //     .then(response => {
            //         setTaskList(response.data.tasks);

            //         // console.log('Rank:', response.data.rank);
            //         // Additional code to handle the response...
            //     })

            await axios.get(`${apiURL}leaderboard`)
                .then(response => {
                    setLeaderboardList(response.data)
                    console.log('Leaderboard: =================', response.data);
                })
                .catch(error => {
                    console.error('Error getting leaderboard:', error);
                });
        }
        req()
    }, [pointCount, openSkins, openFriends])



    const handleOpen = () => setOpenWithdraw(true);
    const handleClose = () => setOpenWithdraw(false);

    // Handle the change of the address input
    const handleAddressChange = (event) => setUserAddress(event.target.value);

    const handleCoinClick = (event) => {
        if (miningInfo.limit > 0) {
            setPointCount(pointCount + miningInfo.perClick);
            setMiningInfo((prevMiningInfo) => {
                const newLimit = prevMiningInfo.limit - miningInfo.perClick;
                if (newLimit <= 0) {
                    // Stop when the limit is reached
                    return { ...prevMiningInfo, limit: 0, status: 'stop' };
                }
                return { ...prevMiningInfo, limit: newLimit, status: 'mining' };
            });

            setExpandAnimation(`${expand} 0.1s ease`);
            setFontSizeAnimation(`${fontSizeAnim} 0.1s ease`);
            audio.play();

            const x = event.clientX;
            const y = event.clientY;

            setTextPoints([...textPoints, { x, y, id: Date.now() }]);
            setTimeout(() => {
                setExpandAnimation('');
                setFontSizeAnimation('');
            }, 200);
        } else {
            if (window.Telegram.WebApp) {
                window.Telegram.WebApp.showAlert('Mining limit reached. Please try again later.');
                // alert("Mining limit reached. Please try again later.")
            }
        }
    };


    // this function will show after user clicked on the button in withdraw modal
    const handleWithdraw = () => {
        if (pointCount >= 50) {
            axios.post(`${apiURL}withdraw`, {
                telegram_id: telegram_id,
                userAddress: userAddress,
                points: pointCount
            })
                .then(response => {
                    message.success('Withdrawal was successfully, please check your wallet!');
                    if (window.Telegram.WebApp) {
                        window.Telegram.WebApp.close();
                    }
                })
                .catch(error => {
                    message.error('Something went wrong, please check SendChain bot!');
                    // Additional code to handle the error...
                });
        } else {
            alert('Insufficient balance. you need to have at least 5000 points to withdraw');
        }
        setOpenWithdraw(false);
    }
    // remove a point after animation is done
    const removePoint = (id) => {
        setTextPoints(textPoints.filter(point => point.id !== id));
    };



    const getCoinSkinShadow = (userCurrentSkinID) => {
        switch (userCurrentSkinID) {
            case 1:
                return '0px 0px 45px #291400';
            case 2:
                return '0px 0px 45px #FAE088';
            case 3:
                return '0px 0px 45px #5c716c';
            case 4:
                return '0px 0px 45px skyblue';
            default:
                return '0px 0px 45px #0152AC';
        }
    }

    const getRewardIcon = (pointCount) => {
        if (pointCount >= 5000000) return '👑';
        if (pointCount >= 1000000) return '🏆';
        if (pointCount >= 500000) return '💎';
        if (pointCount >= 100000) return '🥇';
        return '⚡️';
    };

    const formatNumber = (num) => {
        if (num >= 1000000) return (num / 1000000).toFixed(1) + 'M';
        if (num >= 1000) return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return num;
    };
    // Helper function to get dynamic style based on pointCount
    // Helper function to get dynamic style based on pointCount
    const getDynamicStyle = (pointCount) => {
        const baseStyle = {
            transition: 'all 0.5s ease-in-out',  // Smooth transition
            animation: `${glow} 2s infinite alternate`,  // Glow animation
            borderRadius: '10px',  // Rounded borders
            border: '2px solid gold',  // Gold border
        };

        if (pointCount >= 5000000) {
            return {
                ...baseStyle,
                boxShadow: '0px 0px 45px gold',
                color: 'gold',
                transform: 'scale(1.1)',  // Slightly enlarge for 5M+ points
            };
        } else if (pointCount >= 1000000) {
            return {
                ...baseStyle,
                boxShadow: '0px 0px 45px gold',
                color: 'silver',
                transform: 'scale(1.05)',  // Slightly enlarge for 1M+ points
            };
        } else if (pointCount >= 500000) {
            return {
                ...baseStyle,
                boxShadow: '0px 0px 45px gold',
                color: 'cyan',
            };
        } else {
            return {
                ...baseStyle,
                boxShadow: '0px 0px 45px gold',
                color: 'gold',
            };
        }
    };


    // const getDynamicStyle = (pointCount) => {
    //         if (pointCount >= 5000000) {
    //             return { boxShadow: '0px 0px 45px gold', color: 'gold' };  // Style for 5M+ points
    //         } else if (pointCount >= 1000000) {
    //             return { boxShadow: '0px 0px 45px silver', color: 'silver' };  // Style for 1M+ points
    //         } else if (pointCount >= 500000) {
    //             return { boxShadow: '0px 0px 45px cyan', color: 'cyan' };  // Style for 500K+ points
    //         } else {
    //             return { boxShadow: '0px 0px 45px white', color: 'white' };  // Default style
    //         }
    //     };  



    return (
        <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', p: 1, }} >

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', backdropFilter: 'blur(10px)', borderRadius: '20px', width: `${isDesktop ? '30vw' : '90vw'}`, height: `${isDesktop ? '6.5vw' : '10vh'}` }}>
                <Typography variant="h6" component="p" sx={{ fontWeight: '800', fontFamily: 'Avenir', textAlign: 'center', color: 'white', borderRadius: '15px', border: '2px solid white', padding: '5px 60px', }}>
                    {userData?.first_name}
                </Typography>
            </Box>

            <Box
                sx={{
                    position: 'absolute',
                    top: '12%',
                    textAlign: 'center',
                    marginBottom: '20px',
                    animation: fontSizeAnimation,
                    ...getDynamicStyle(pointCount),  // Apply dynamic styles based on points
                }}
            >
                <Typography variant="body1" component="p" sx={{ fontFamily: 'avenir' }}>
                    Your zak's balance:
                </Typography>
                <Typography variant="h2" component="p" sx={{ fontWeight: '900', fontFamily: 'avenir', fontSize: '3.5rem' }}>
                    {getRewardIcon(pointCount)} {formatNumber(pointCount)}
                </Typography>
            </Box>



            {/* <Typography component="p" sx={{ fontWeight: '600', fontFamily: 'avenir', position: 'absolute', top: '24%', color: 'aliceblue', fontSize: `${isDesktop ? '14px' : '16'}`, zIndex: 1 }}>
                <img src={leafRight} alt="leaf" style={{ filter: 'invert(1)', width: '40px', verticalAlign: 'middle', transform: 'scaleX(-1)' }} />
                Ranking : {userCurrentRank === null ? 'Loading...' : userCurrentRank === 1 ? '1st' : userCurrentRank === 2 ? '2nd' : userCurrentRank === 3 ? '3rd' : `........`}
                <img src={leafRight} alt="leaf" style={{ filter: 'invert(1)', width: '40px', verticalAlign: 'middle' }} />
            </Typography> */}

            <CoinLogo component="img" src={userCurrentSkinImage || defaultCoin} alt="Coin Logo" onClick={handleCoinClick}
                sx={{ position: 'absolute', top: '30%', animation: expandAnimation, "&:hover": { cursor: 'pointer', }, filter: `drop-shadow(${getCoinSkinShadow(userCurrentSkinID)})` }} />

            {textPoints.map((point) => (
                <Box
                    key={point.id}
                    sx={{
                        position: 'absolute',
                        left: point.x - 10,
                        top: point.y - 20,
                        animation: `${floatUpAndFadeOut} 1s ease forwards`, // forwards keeps the end state after animation completes
                        fontSize: `${isDesktop ? '40px' : '35px'}`,
                        fontFamily: 'avenir',
                        color: 'white',
                    }}
                    onAnimationEnd={() => removePoint(point.id)} // remove element after animation
                >
                    +{miningInfo.perClick}
                </Box>
            ))}

            {/* <p style={{position: 'absolute', top: '77%', left: '5vw', color: 'aliceblue', animation: fontSizeAnimation, fontFamily: "avenir", fontSize: `${isDesktop ? '18px' : '13px'}`}}>
         <img style={{verticalAlign:'bottom'}} width="28" height="30" src="https://img.icons8.com/fluency/48/flash-on.png" alt="flash-on"/>
          <span style={{fontSize: `${isDesktop ? '25px' : '20px'}`}}> {miningInfo.limit} </span> / {miningInfo.max}
      </p>

      <LinearProgress 
         variant="buffer" 
         // if he used 50% of coin limits the background is like orage, if is 100% bg is red (with white) and in start is green
         color={(miningInfo.limit / miningInfo.max) * 100 === 100 ? 'secondary' : (miningInfo.limit / miningInfo.max) * 100 >= 50 ? 'warning' : 'error'}
         sx={{ 
          width: `${isDesktop ? '30vw' : '90vw'}`, 
          height: `${isDesktop ? '1.5vh' : '4vh'}`, 
          position: 'absolute', 
          top: '84%', 
          borderRadius: '10px',  
          "& .MuiLinearProgress-dashed": { 
            right: '0px',
          }
        }} 
          value={(miningInfo.limit / miningInfo.max) * 100} 
          valueBuffer={(Math.random() * 10) + (miningInfo.limit / miningInfo.max) * 100} 
      /> */}

            <Box sx={{ padding: '20px', borderRadius: '20px', width: isDesktop ? '30vw' : '90vw' }}>
                {/* Progress Bar */}
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
                    <LinearProgress
                        variant="determinate"
                        value={(miningInfo.limit / miningInfo.max) * 100}
                        sx={{
                            width: '100%',
                            height: '10px',
                            borderRadius: '10px',
                            backgroundColor: '#4b4b6a',
                            '& .MuiLinearProgress-bar': {
                                backgroundColor: '#a084e8'
                            }
                        }}
                    />
                    <Typography sx={{ color: '#fff', marginLeft: '10px' }}>
                        ⚡ {miningInfo.limit}/{miningInfo.max}
                    </Typography>
                </Box>

                {/* Tasks and Withdraw Buttons */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                    <Button
                        onClick={() => setOpenTask(true)}
                        startIcon={
                            <Badge
                                variant="dot"
                                color="error"
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left', // Place dot on the top-left side of the icon
                                }}
                                invisible={false} // Dot is only visible if there are tasks
                            >
                                <img
                                    src="https://img.icons8.com/?size=100&id=ovtKq5XMsHzx&format=png&color=000000"
                                    alt="Tasks"
                                    style={{ width: '24px', height: '24px' }}
                                />
                            </Badge>
                        }
                        sx={{
                            flex: 1,
                            marginRight: '10px',
                            backgroundColor: '#2c2c3d',
                            color: '#fff',
                            borderRadius: '15px',
                            padding: '10px',
                            textTransform: 'none',
                            '&:hover': {
                                backgroundColor: '#2c2c3d',
                            },
                        }}
                    >
                        Tasks
                    </Button>

                    <Button
                        // onClick={handleWithdrawClick}
                        startIcon={
                            <img
                                src="https://img.icons8.com/?size=100&id=43967&format=png&color=000000"
                                alt="Withdraw"
                                style={{ width: '24px', height: '24px' }}
                            />
                        }
                        sx={{
                            flex: 1,
                            marginLeft: '10px',
                            backgroundColor: '#2c2c3d',
                            color: '#fff',
                            borderRadius: '15px',
                            padding: '10px',
                            textTransform: 'none',
                            position: 'relative', // Allows badge positioning
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Soft shadow for the button
                            transition: 'transform 0.2s ease-in-out', // Smooth hover effect
                            '&:hover': {
                                backgroundColor: '#32324d',
                                transform: 'scale(1.05)', // Slightly enlarges the button on hover
                            },
                        }}
                    >
                        Wallet
                        <span
                            style={{
                                position: 'absolute',
                                top: '-10px',
                                right: '-10px',
                                background: 'linear-gradient(135deg, #ff416c, #ff4b2b)', // Gradient background for the badge
                                color: '#fff',
                                borderRadius: '12px', // More rounded badge
                                padding: '5px 12px',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                boxShadow: '0px 4px 8px rgba(255, 75, 43, 0.5)', // Shadow for the badge
                                textTransform: 'uppercase',
                                whiteSpace: 'nowrap',
                                letterSpacing: '0.5px',
                                transition: 'transform 0.2s ease-in-out, opacity 0.2s ease-in-out',
                                animation: `pulse 1.5s infinite`, // Apply pulsating effect
                            }}
                        >
                            Coming Soon
                        </span>
                    </Button>

                    <style>
                        {`
    @keyframes pulse {
        0% {
            transform: scale(1);
            opacity: 1;
        }
        50% {
            transform: scale(1.1);
            opacity: 0.8;
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }
`}
                    </style>

                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                    <Button
                        onClick={() => setOpenLeaderboard(true)}
                        startIcon={<img src="https://img.icons8.com/?size=100&id=48316&format=png&color=000000" alt="Tasks" style={{ width: '24px', height: '24px' }} />} // URL to the Task icon
                        sx={{
                            flex: 1,
                            marginRight: '10px',
                            backgroundColor: '#2c2c3d',
                            color: '#fff',
                            borderRadius: '15px',
                            padding: '10px',
                            textTransform: 'none',
                            '&:hover': {
                                backgroundColor: '#2c2c3d'
                            }
                        }}
                    >
                        Leaderboard
                    </Button>
                    <Button
                        onClick={() => setOpenFriends(true)}
                        startIcon={<img src="https://img.icons8.com/?size=100&id=4N0dgOeajVna&format=png&color=000000" alt="Withdraw" style={{ width: '24px', height: '24px' }} />} // URL to the Withdraw icon
                        sx={{
                            flex: 1,
                            marginLeft: '10px',
                            backgroundColor: '#2c2c3d',
                            color: '#fff',
                            borderRadius: '15px',
                            padding: '10px',
                            textTransform: 'none',
                            '&:hover': {
                                backgroundColor: '#2c2c3d'
                            }
                        }}
                    >
                        Freinds
                    </Button>
                </Box>

                {/* Navigation Links */}
                {/* <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        backgroundColor: '#2c2c3d',
                        borderRadius: '15px',
                        padding: '10px',
                        color: '#fff'
                    }}
                >
                    <Typography sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                        <img src="https://img.icons8.com/?size=100&id=46095&format=png&color=000000" alt="Earn" style={{ width: '24px', height: '24px', marginBottom: '5px' }} />
                        Earn
                    </Typography>
                    <Typography sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                        <img src="https://img.icons8.com/?size=100&id=5CtiUq0Pvtnd&format=png&color=000000" alt="Tasks" style={{ width: '24px', height: '24px', marginBottom: '5px' }} />
                        Tasks
                    </Typography>
                    <Typography sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                        <img src="https://img.icons8.com/?size=100&id=44782&format=png&color=000000" alt="Town" style={{ width: '24px', height: '24px', marginBottom: '5px' }} />
                        Town
                    </Typography>
                    <Typography sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                        <img src="https://img.icons8.com/?size=100&id=71056&format=png&color=000000" alt="Swap" style={{ width: '24px', height: '24px', marginBottom: '5px' }} />
                        Swap
                    </Typography>
                    <Typography sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                        <img src="https://img.icons8.com/?size=100&id=49439&format=png&color=000000" alt="Account" style={{ width: '24px', height: '24px', marginBottom: '5px' }} />
                        Account
                    </Typography>
                </Box> */}
            </Box>



            {/* Skins Modal */}
            <SkinsModal open={openSkins} handleClose={() => setOpenSkins(false)} userData={userData} userSkins={userSkins} userCurrentSkin={userCurrentSkinID} />

            {/* Friends Modal */}
            <FriendsModal open={openFriends} handleClose={() => setOpenFriends(false)} userData={userData} referralCount={userCurrentReferrals} referralList={userReferralsInfo === 'null' ? [] : userReferralsInfo} />

            {/* Leaderboard Modal */}
            <LeaderboardModal open={openLeaderboard} handleClose={() => setOpenLeaderboard(false)} userData={userData} leaderboardList={leaderboardList} />

            {/* Task Modal */}
            <TaskModal open={openTask}  fetchUserData={fetchUserData} handleClose={() => setOpenTask(false)} userData={userData} taskList={taskList} telegram_id={telegram_id} />

        </Box>
    );
}
