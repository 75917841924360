import React, { useState, useEffect } from 'react';
import CoinApp from './components/CoinApp';
import { ThemeProvider, createTheme } from '@mui/material';
import axios from 'axios';
import { apiURL } from './api';
import './App.css';
import './css/customStyle.css';
import { Vortex } from 'react-loader-spinner';

const theme = createTheme();
const telApp = window.Telegram.WebApp;
const isPhone = window.innerWidth < 600;
const url = window.location.href;
const telegram_id = url.split('/').pop();

function App() {
  const [userData, setUserData] = useState(null);
  const [profileUrl, setProfileUrl] = useState("https://img.icons8.com/?size=100&id=20749&format=png&color=000000");
  const [pointCount, setPointCount] = useState(1000);
  const [isTelegramMiniApp, setIsTelegramMiniApp] = useState(true);
  const [miningInfo, setMiningInfo] = useState({
    status: 'idle',
    perClick: 2,
    limit: 100,
    max: 100,
  });
  const [loading, setLoading] = useState(true);
  
  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${apiURL}user/${telegram_id}`);
      setUserData(response?.data);
      setPointCount(response?.data?.points);
      // console.log('userDetailsresponse:', response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    telApp.ready();
    init();

    fetchUserData();
  }, [telegram_id]);

  useEffect(() => {
    if (userData && Object.keys(userData).length > 0) {
      getUserProfile();
      handleSignUp();
      handleMiningInfo();
      setLoading(false); // Set loading to false only when userData is ready
    }
  }, [userData]);

  const init = () => {
    setIsTelegramMiniApp(true);
  };

  const getUserProfile = async () => {
    setProfileUrl("https://img.icons8.com/?size=100&id=20749&format=png&color=000000");
  };

  const handleMiningInfo = () => {
    if (!userData) return;
  
    setMiningInfo(prevMiningInfo => ({
      ...prevMiningInfo,
      limit: 100,
    }));
  };

  const handleSignUp = () => {
    if (!userData) return;
    console.log('Signup was successful');
  };

  return (
    <>
      <div className="App">
        {loading ? (
          <div style={{
            textAlign: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'radial-gradient(circle, rgb(17, 8, 48) 75%, rgb(17, 8, 48))',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Vortex
              visible={true}
              height="250"
              width="250"
              ariaLabel="vortex-loading"
              wrapperStyle={{}}
              wrapperClass="vortex-wrapper"
              colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
            />
          </div>
        ) : (
          isPhone && isTelegramMiniApp ? (
            <ThemeProvider theme={theme}>
              <CoinApp
                userData={userData}
                profileUrl={profileUrl}
                telApp={telApp}
                telegram_id={userData?.telegram_id} // Use optional chaining
                pointCount={pointCount}
                setPointCount={setPointCount}
                miningInfo={miningInfo}
                setMiningInfo={setMiningInfo}
                fetchUserData={fetchUserData}
              /> 
            </ThemeProvider>
          ) : (
            <div style={{ height: '110vh' }}>
              <h3 style={{ textAlign: 'center', background: 'rgb(216 215 215 / 42%)', display: 'inline-flex', padding: '20px', marginTop: '40vh', borderRadius: '20px' }}>
                You need to open with the Telegram bot!
              </h3>
              <h3>
                <a href='https://t.me/zakscoin_bot' style={{ textDecoration: 'none', color: 'darkmagenta' }}>
                  <img style={{ verticalAlign: 'middle', marginBottom: '16px' }} width="70" height="70" src="https://img.icons8.com/3d-fluency/94/robot-1.png" alt="robot-1" />
                  <span> Go to Zakscoin_bot </span>
                </a>
              </h3>
            </div>
          )
        )}
      </div>
    </>
  );
}

export default App;
